<div class="flex lg:w-1/5 md:w-1/2 w-full mt-8 mb-4">
  <app-text-input
    [label]="'Phone Number'"
    [placeholder]="'Search by phone number'"
    [formControl]="filtersForm.get('SearchQ')"
    [name]="'recording-searcher'" />

  <app-dropdown
    [label]="'Worker'"
    [placeholder]="'Search Workers'"
    [search]="true"
    [searching]="searchingWorkers"
    [dynamicOptions]="true"
    [formControl]="filtersForm.get('workerId')"
    [defaultOption]="{ text: 'All Workers', value: '' }"
    (onChangeSearch)="searchWorkerOptions($event)"
    [options]="workerOptions"
    class="ml-8" />
  
  <app-dropdown
    [label]="'Caller'"
    [placeholder]="'Search Calling User'"
    [search]="true"
    [searching]="searchingCallers"
    [dynamicOptions]="true"
    [formControl]="filtersForm.get('callerId')"
    [defaultOption]="{ text: 'All Callers', value: '' }"
    (onChangeSearch)="searchCallerOptions($event)"
    [options]="callerOptions"
    class="ml-8" />
</div>