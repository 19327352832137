import { APP_ID, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AppErrorHandler } from 'src/app/core/interceptors/app-error-handler';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AdminState } from './features/admin/admin.state';
import { TimeheetsState } from './features/timesheets/states/timesheets/timesheets.state';
import { UsersState } from './features/users/state/users.state';
import { PrefillDataState } from './shared/states/prefill-data/prefill-data.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(
      withFetch()
    ),
    provideEnvironmentNgxMask(),
    provideNativeDateAdapter(),
    importProvidersFrom(
      HttpClientModule,
      ReactiveFormsModule,
      MatSnackBarModule,
      NgxsModule.forRoot([
          AdminState,
          UsersState,
          PrefillDataState,
          TimeheetsState
        ], {
        developmentMode: !environment.production,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production,
      }),
      NgxsLoggerPluginModule.forRoot({ disabled: true }),
    ),
    { 
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { 
      provide: APP_ID,
      useValue: 'serverApp'
    }
  ]
};
