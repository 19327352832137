import { CommonModule, Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { MainPageComponent } from "./core/components/main-page/main-page.component";
import { EnvironmentService } from "./core/services/environment.service";
import { LoadPrefillData, LoadTenantId } from "./shared/states/prefill-data/prefill-data.actions";
import { PrefillDataState } from "./shared/states/prefill-data/prefill-data.state";

@Component({
	selector: "app-root",
	standalone: true,
	imports: [CommonModule, MainPageComponent],
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
	title = "Maritime";

	@Select(state => state.users.loaded) user$: Observable<any>;
	useTenant: boolean = false; // To know if user is in url that uses tenant or not
	showApp: boolean = false; // To show the app after the tenant is correctly loaded 

	constructor(
		private router: Router,
		private location: Location,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private store: Store,
		private env: EnvironmentService
	) {}

	ngOnInit(): void {
		this._changeTitleOnNavigation();
		this.subscribeUser();

		if (this.env.isBrowser) {
			const arrayHostname = window.location.hostname.split(".");
			if (arrayHostname.length == 2) {
				this.router.navigateByUrl('/redirect');
			} else if (arrayHostname.length != 2 && this.location.path() == '/redirect') {

				const protocol: string = window.location.protocol;
				const port: string = window.location.port;
				const url: string = port != '' ? `${protocol}://${arrayHostname[1]}.${arrayHostname[2]}:${port}/redirect`
					: `${protocol}://${arrayHostname[1]}.${arrayHostname[2]}/redirect`;

				window.location.href = url;
			} else {
				this.useTenant = true; // At this point the user is in a selected port, so we have to set the tenantId
				const tenantId = this.store.selectSnapshot<string>((state) => state.prefillData?.tenantId || null);
				if (arrayHostname[0] && !tenantId) {
					this.store.dispatch(new LoadTenantId(arrayHostname[0]));
				}
			}
		}

		this.subscribeTenantLoaded();
	}

	private _changeTitleOnNavigation() {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => {
					let route = this.activatedRoute;
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === "primary"),
				switchMap(route => route.data)
			)
			.subscribe(data => {
				if (data.title) {
					this.titleService.setTitle(`${data.title} - ${this.title}`);
				} else {
					this.titleService.setTitle(this.title);
				}
			});
	}

	private subscribeUser(): void {
		this.user$.subscribe(res => {
			if (res) {
				this.store.dispatch(new LoadPrefillData());
			}
		});
	}
	
	private subscribeTenantLoaded(): void {
		// If in redirect page (not uses tenant), or if it uses tenant and is loaded then show the app
		this.store.select(PrefillDataState.tenantLoaded).subscribe(res => {
			if ((!this.useTenant) || (this.useTenant && res === true)) this.showApp = true; 
		});
	}
}
