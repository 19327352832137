<div class="h-screen">
  @if (user$ | async) {
    <app-header />
  }

  @if ((prefillLoaded$ | async) || !(user$ | async)) {
    <router-outlet />
  } @else {
    @if (prefillDataError$ | async) {
      <div class="flex justify-center p-8 text-red-600">
        Something went wrong. Please refresh the page.
      </div>
    }
    <div class="flex justify-center ">
      <img src="/assets/rings.svg" alt="" class="w-20 opacity-75 ml-8 py-48" />
    </div>
  }
</div>