import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { RecordingsListComponent } from "./components/recordings-list/recordings-list.component";
import { RecordingsState } from "./state/recordings.state";

export const recordingRoutes: Route[] = [
  {
    path: routeSettings.recordings.path,
    canActivate: [authGuard],
    data: {
      title: routeSettings.recordings.title,
      permissions: [
        Permission.Administrator,
        Permission.CallRecords
      ]
    },
    providers: [importProvidersFrom(NgxsModule.forFeature([RecordingsState]))],
    loadComponent: () => import("./components/recordings/recordings.component").then(c => c.RecordingsComponent),
    children: [
      {
        path: '',
        component: RecordingsListComponent
      },
    ]
  }
];