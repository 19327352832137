import { Routes } from "@angular/router";

import { importProvidersFrom } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { BerthsState } from "./berths/state/berths.state";
import { CargoTypesState } from "./cargo-types/state/cargo-types.state";
import { CertificationsState } from "./certifications/state/certifications.state";
import { DebitTypesState } from "./debit-types/state/debit-types.state";
import { DeptCodesState } from "./dept-codes/state/dept-codes.state";
import { EmployersState } from "./employers/state/employers.state";
import { MessagesState } from "./messages/state/messages.state";
import { NotificationGroupsState } from "./notification-groups/state/notification-group.state";
import { OccCodesSteadyState } from "./occ-codes-steady/state/occ-codes.steady.state";
import { PiersState } from "./piers/state/piers.state";
import { PortsState } from "./ports/state/ports.state";
import { ReportsState } from "./reports/state/reports.state";
import { SuspensionTypesState } from "./suspension-types/state/suspension-types.state";
import { SystemConfigsState } from "./system-configs/state/system-configs.state";
import { TerminalsState } from "./terminals/state/terminals.state";
import { UnionsState } from "./unions/state/unions.state";
import { UsersPermissionsState } from "./users-permissions/state/users-permissions.state";
import { VesselsState } from "./vessels/state/vessels.state";

export const adminRoutes: Routes = [
  {
    path: routeSettings.admin.path,
    canActivate: [authGuard],
		data: {
			permissions: [
				Permission.Administrator,
				Permission.EmployerTest,
				Permission.EmployerSupervisor,
				Permission.SingleStepApprover
			]
		},
    loadComponent: () => import("./admin.component").then(c => c.AdminComponent),
    children: [
      {
        path: routeSettings.admin.ports.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.ports.title,
          permissions: [
            Permission.Administrator, 
            Permission.EmployerTest
          ]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([PortsState]))],
        loadComponent: () => import("./ports/ports/ports.component").then(c => c.PortsComponent),
      },
      {
        path: routeSettings.admin.terminals.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.terminals.title,
          permissions: [
            Permission.Administrator,
            Permission.EmployerTest
          ]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([TerminalsState, PortsState, EmployersState]))],
        loadComponent: () => import("./terminals/terminals/terminals.component").then(c => c.TerminalsComponent),
      },
      {
        path: routeSettings.admin.piers.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.piers.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([PiersState, TerminalsState, PortsState]))],
        loadComponent: () => import("./piers/piers/piers.component").then(c => c.PiersComponent),
      },
      {
        path: routeSettings.admin.berths.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.berths.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([BerthsState]))],
        loadComponent: () => import("./berths/berths/berths.component").then(c => c.BerthsComponent),
      },
      {
        path: routeSettings.admin.deptCodes.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.deptCodes.title,
          permissions: [Permission.Administrator, Permission.EmployerTest]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([DeptCodesState, TerminalsState]))],
        loadComponent: () => import("./dept-codes/dept-codes/dept-codes.component").then(c => c.DeptCodesComponent),
      },
      {
        path: routeSettings.admin.occCodesSteady.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.occCodesSteady.title,
          permissions: [
            Permission.Administrator,
            Permission.EmployerSupervisor,
            Permission.SingleStepApprover
          ]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([UnionsState, OccCodesSteadyState, EmployersState]))],
        loadComponent: () => import("./occ-codes-steady/occ-codes-steady/occ-codes-steady.component").then(c => c.OccCodesSteadyComponent),
      },
      {
        path: routeSettings.admin.unions.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.unions.title,
          permissions: [
            Permission.Administrator,
            Permission.EmployerTest
          ]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([UnionsState, PortsState]))],
        loadComponent: () => import("./unions/unions/unions.component").then(c => c.UnionsComponent),
      },
      {
        path: routeSettings.admin.vessels.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.vessels.title,
          permissions: [
            Permission.Administrator,
            Permission.EmployerTest
          ]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([VesselsState, PortsState]))],
        loadComponent: () => import("./vessels/vessels/vessels.component").then(c => c.VesselsComponent),
      },
      {
        path: routeSettings.admin.employers.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.employers.title,
          permissions: [Permission.Administrator, Permission.EmployerTest]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([EmployersState]))],
        loadComponent: () => import("./employers/employers/employers.component").then(c => c.EmployersComponent),
      },
      {
        path: routeSettings.admin.certifications.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.certifications.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([CertificationsState, EmployersState]))],
        loadComponent: () => import("./certifications/components/certifications/certifications.component").then(c => c.CertificationsComponent),
      },
      {
        path: routeSettings.admin.userPermissions.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.userPermissions.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([
          UsersPermissionsState,
          PortsState,
          TerminalsState,
          EmployersState,
          UnionsState,
          ReportsState,
          SuspensionTypesState
        ]))],
        loadComponent: () => import("./users-permissions/components/users-permissions/users-permissions.component").then(c => c.UsersPermissionsComponent),
      },
      {
        path: routeSettings.admin.reports.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.reports.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([ReportsState]))],
        loadComponent: () => import("./reports/reports/reports.component").then(c => c.ReportsComponent),
      },
      {
        path: routeSettings.admin.messages.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.messages.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([MessagesState]))],
        loadComponent: () => import("./messages/components/messages/messages.component").then(c => c.MessagesComponent),
      },
      {
        path: routeSettings.admin.suspensionTypes.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.suspensionTypes.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([SuspensionTypesState, UnionsState]))],
        loadComponent: () => import("./suspension-types/suspension-types/suspension-types.component").then(c => c.SuspensionTypesComponent),
      },
      {
        path: routeSettings.admin.cargoTypes.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.cargoTypes.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([UnionsState, EmployersState, CargoTypesState]))],
        loadComponent: () => import("./cargo-types/components/cargo-types/cargo-types.component").then(c => c.CargoTypesComponent),
      },
      {
        path: routeSettings.admin.debitTypes.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.debitTypes.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([DebitTypesState]))],
        loadComponent: () => import("./debit-types/debit-types/debit-types.component").then(c => c.DebitTypesComponent),
      },
      {
        path: routeSettings.admin.systemConfigs.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.systemConfigs.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([SystemConfigsState]))],
        loadComponent: () => import("./system-configs/components/system-configs/system-configs.component").then(c => c.SystemConfigsComponent),
      },
      {
        path: routeSettings.admin.notificationGroups.path,
        canActivate: [authGuard],
        data: {
          title: routeSettings.admin.notificationGroups.title,
          permissions: [Permission.Administrator]
        },
        providers: [importProvidersFrom(NgxsModule.forFeature([NotificationGroupsState, UnionsState, EmployersState]))],
        loadComponent: () => import("./notification-groups/components/notification-groups/notification-groups.component").then(c => c.NotificationGroupsComponent),
      }
    ]
  }
];