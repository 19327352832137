import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "src/environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { RecordingFilters } from "../interfaces/recording-filters";

@Injectable({
  providedIn: "root"
})
export class RecordingsService {
  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getRecordings(filters: RecordingFilters) {
    const params: HttpParams = new HttpParams({
      fromObject: { ...filters }
    });
    return this.http.get<any>(`${this.apiUrl}/app/workerCall/recordList`, { params });
  }

  removeRecording(recordingId: string) {
    return this.http.delete(`${this.apiUrl}/app/workerCall/${recordingId}/record`);
  }
}
