import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime',
  standalone: true
})

export class SecondsToTimePipe implements PipeTransform {
  transform(value: number): any {
    return new Date(value * 1000).toISOString().substring(11, 19);
  }
}