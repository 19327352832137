import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { routeSettings } from "src/app/core/routes.config";
import { EmployersState } from "src/app/features/admin/employers/state/employers.state";
import { TerminalsState } from "src/app/features/admin/terminals/state/terminals.state";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { PodiumState } from "./state/podium.state";

export const podiumRoutes: Route[] = [
  {
    path: routeSettings.podium.path,
    canActivate: [authGuard],
    providers: [importProvidersFrom(NgxsModule.forFeature([
      PodiumState,
      TerminalsState,
      EmployersState,
      UnionsState
    ]))],
    data: {
      title: routeSettings.dispatch.title,
      permissions: [
        Permission.Administrator,
        Permission.UnionDispatcher
      ]
    },
    loadComponent: () => import("./components/podium/podium.component").then(c => c.PodiumComponent)
  },
];