import { RecordingFilters } from "../interfaces/recording-filters";

export class LoadRecordings {
  public static readonly type = "[Recordings] load recordings";
  constructor(
    public filters: RecordingFilters
  ) {}
}

export class RemoveRecording {
  public static readonly type = "[Recordings] delete recording";
  constructor(
    public recordingId: string
  ) {}
}