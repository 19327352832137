import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { routeSettings } from "src/app/core/routes.config";
import { CertificationsState } from "src/app/features/admin/certifications/state/certifications.state";
import { EmployersState } from "src/app/features/admin/employers/state/employers.state";
import { SuspensionTypesState } from "src/app/features/admin/suspension-types/state/suspension-types.state";
import { TerminalsState } from "src/app/features/admin/terminals/state/terminals.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { pendingChangesGuard } from "src/app/shared/guards/pendingChanges.guard";
import { BerthsState } from "../admin/berths/state/berths.state";
import { WorkerState } from "./state/worker.state";

export const workerRoutes: Route[] = [
	{
		path: 'worker/add',
		canActivate: [authGuard],
		data: {
			title: routeSettings.roster.addWorker.title,
			status: 'creating',
			permissions: [
				Permission.Administrator,
				Permission.UnionAdministrator,
				Permission.UnionAdminReadonly,
				Permission.UnionDispatcher
			]
		},
		providers: [
			importProvidersFrom(
				NgxsModule.forFeature([
					WorkerState,
					EmployersState,
					TerminalsState,
					SuspensionTypesState,
					CertificationsState,
				])
			)
		],
		loadComponent: () => import("./components/worker/worker.component").then(c => c.WorkerComponent),
		children: [
			{
				path: "profile",
				canDeactivate: [pendingChangesGuard],
				loadComponent: () => import("./components/profile/profile.component").then(c => c.ProfileComponent),
			},
			{
				path: "worker-information",
				loadComponent: () => import("./components/worker-information/worker-information.component").then(c => c.WorkerInformationComponent),
				providers: [
					importProvidersFrom(
						NgxsModule.forFeature([
							BerthsState
						])
					)
				],
			},
			{
				path: '**',
				pathMatch: 'full',
				redirectTo: 'profile'
			}
		]
	},
	{
		path: 'worker/edit/:id',
		canActivate: [authGuard],
		data: {
			title: routeSettings.roster.editWorker.title,
			status: 'editing',
			permissions: [
				Permission.Administrator,
				Permission.UnionAdministrator,
				Permission.UnionAdminReadonly,
				Permission.UnionDispatcher,
				Permission.StevedoringAssociation
			]
		},
		providers: [
			importProvidersFrom(
				NgxsModule.forFeature([
					WorkerState,
					EmployersState,
					TerminalsState,
					SuspensionTypesState,
					CertificationsState
				])
			)
		],
		loadComponent: () => import("./components/worker/worker.component").then(c => c.WorkerComponent),
		children: [
			{
				path: "profile",
				canDeactivate: [pendingChangesGuard],
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/profile/profile.component").then(c => c.ProfileComponent),
			},
			{
				path: "worker-information",
				canDeactivate: [pendingChangesGuard],
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/worker-information/worker-information.component").then(c => c.WorkerInformationComponent),
				providers: [
					importProvidersFrom(
						NgxsModule.forFeature([
							BerthsState
						])
					)
				],
			},
			{
				path: "suspensions",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/suspensions/suspensions.component").then(c => c.SuspensionsComponent),
			},
			{
				path: "incidents",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/incidents/incidents.component").then(c => c.IncidentsComponent),
			},
			{
				path: "certifications",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/worker-certifications/worker-certifications.component").then(c => c.WorkerCertificationsComponent),
			},
			{
				path: "communications",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/communications/communications.component").then(c => c.CommunicationsComponent),
			},
			{
				path: "profile-history",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/profile-history/profile-history.component").then(c => c.ProfileHistoryComponent),
			},
			{
				path: "work-history",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/work-history/work-history.component").then(c => c.WorkHistoryComponent),
			},
			{
				path: "payments",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/payments/payments.component").then(c => c.PaymentsComponent),
			},
			{
				path: "debits",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.StevedoringAssociation
					]
				},
				loadComponent: () => import("./components/debits/debits.component").then(c => c.DebitsComponent),
			},
			{
				path: "documents",
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionDispatcher
					]
				},
				loadComponent: () => import("./components/documents/documents.component").then(c => c.DocumentsComponent),
			}
		]
	}
];
