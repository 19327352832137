<div class="mb-2">
  <h2>Recordings List</h2>

  <app-recordings-filters />

  <app-table>
    <app-table-row-group>
      <app-table-header-row>
        @for (header of tableHeaders; track $index) {
          <app-table-header-cell [ngClass]="{ 'border-b border-gray-200': isLoading$ | async }">
            {{ header }}
          </app-table-header-cell>
        }
      </app-table-header-row>
    </app-table-row-group>

    <app-table-row-group>
      @if (isLoading$ | async) {
        <app-table-loading-spinner [colspan]="'6'"></app-table-loading-spinner>
      } @else if (error$ | async) {
        <tr>
          <td colspan="6" class="text-center text-red-500 py-8">
            There was a problem loading recordings. Please refresh the page.
          </td>
        </tr>
      } @else if ((recordings$ | async).length === 0) {
        <tr>
          <td colspan="6" class="text-center py-8">
            There are currently no recordings to show.
          </td>
        </tr>
      } @else {
        @for (recording of recordings$ | async; track $index; let odd = $odd) {
          <app-table-row [ngClass]="{ 'bg-gray-200': odd }" [attr.data-testid]="recording.id">
            <app-table-cell class="border-l pl-6">
              <div class="flex flex-col">
                <span>{{ recording.workerFullName }}</span>
                <span>{{ recording.workerPhone }}</span>
              </div>
            </app-table-cell>
            <app-table-cell>
              <span>{{ recording.callerName }}</span>
            </app-table-cell>
            <app-table-cell>
              <span>{{ recording.creationTime | momentFormat:'complete' }}</span>
            </app-table-cell>
            <app-table-cell>
              <span class="capitalize">{{ recording.recordStatus }}</span>
            </app-table-cell>
            <app-table-cell>
              <span>{{ recording.duration | secondsToTime }}</span>
            </app-table-cell>
            <app-table-cell class="border-r text-right pr-6">
              <div class="flex justify-center items-center">
                <audio controls>
                  <source type="audio/mpeg" [src]="recording.callUrl" />
                </audio>
                <button type="button" class="basic-button ml-8" (click)="deleteRecording(recording)">
                  Delete
                </button>
              </div>
            </app-table-cell>
          </app-table-row>
        }
      }
    </app-table-row-group>
  </app-table>

  @if (totalCount$ | async) {
    <mat-paginator
      [length]="totalCount$ | async"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25]"
      (page)="getPage($event)"
      class="w-full" />
  }
</div>