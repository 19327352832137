import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { routeSettings } from "../../core/routes.config";
import { UserProfileState } from "./state/user-profile.state";

export const userProfileRoutes: Route[] = [
  {
    path: routeSettings.profile.path,
    data: {
			title: routeSettings.profile.title
		},
    providers: [importProvidersFrom(NgxsModule.forFeature([UserProfileState]))],
    loadComponent: () => import("./components/user-profile/user-profile.component").then(c => c.UserProfileComponent),
  }
];