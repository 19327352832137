import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TimeSheet } from '../../../core/interfaces/timesheet.interface';
import { GetTimeSheetById } from '../states/timesheets/timesheets.actions';
import { TimeheetsState } from '../states/timesheets/timesheets.state';

@Injectable({ providedIn: 'root' })
export class LoadTimesheetResolver  {

    constructor(
        private router: Router,
        private store: Store,
        private notificationService: NotificationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const timesheetId: string = route.params.timesheetId;
        if (timesheetId) {
            this.store.dispatch(new GetTimeSheetById(timesheetId)).toPromise().then(
                (response) => {
                    const isWorkerList: boolean = state.url.includes('workers-list');
                    const timesheet = this.store.selectSnapshot(TimeheetsState.selectedItem);
                    if (!isWorkerList || (isWorkerList && this.isValidTimesheet(JSON.parse(timesheet)))) {
                        return timesheet;
                    } else {
                        this.notificationService.showError("Please, complete required fields");
                        this.router.navigate(this.getRouterLink(state.url));
                    }
                },
                (error) => {
                    this.router.navigate(['/timekeeping']);
                }
            );
        } else {
            this.router.navigate(['/timekeeping']);
        }
    }

    private isValidTimesheet(timesheet: TimeSheet): boolean {
        return !!(timesheet.timeKeeperUserId && timesheet.workStartDate && timesheet.cargoType);
    }

    private getRouterLink(url: string): Array<string> {
        const currentRouterLink: Array<string> = url.split('/');
        currentRouterLink.splice(currentRouterLink.length - 1, 1);
        currentRouterLink.splice(0, 1);
        return currentRouterLink;
    }
}
