import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { routeSettings } from "src/app/core/routes.config";
import { BerthsState } from "src/app/features/admin/berths/state/berths.state";
import { DeptCodesState } from "src/app/features/admin/dept-codes/state/dept-codes.state";
import { EmployersState } from "src/app/features/admin/employers/state/employers.state";
import { PortsState } from "src/app/features/admin/ports/state/ports.state";
import { TerminalsState } from "src/app/features/admin/terminals/state/terminals.state";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { VesselsState } from "src/app/features/admin/vessels/state/vessels.state";
import { RosterState } from "src/app/features/roster/states/roster/roster.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { DashboardState } from "src/app/shared/states/dashboard.state";
import { OrderState } from "./state/order.state";

export const ordersRoutes: Route[] = [
  {
    path: routeSettings.orders.path,
    providers: [importProvidersFrom(NgxsModule.forFeature([
      DashboardState,
      OrderState,
      RosterState,
      UnionsState,
      EmployersState,
      PortsState,
      VesselsState,
      TerminalsState,
      DeptCodesState,
      BerthsState
    ]))],
    canActivate: [authGuard],
    data: {
      permissions: [
        Permission.Administrator,
        Permission.UnionDispatcher,
        Permission.EmployerTest,
        Permission.UnionSupervisor
      ]
    },
    loadComponent: () => import("./components/orders/orders.component").then(c => c.OrdersComponent),
    children: [
      {
        path: "",
        loadComponent: () => import("./components/orders-main/orders-main.component").then(c => c.OrdersMainComponent),
        data: {
          title: routeSettings.orders.title
        }
      },
      {
        path: routeSettings.orders.createOrder.path,
        loadComponent: () => import("./components/create-order/create-order.component").then(c => c.CreateOrderComponent),
        data: {
          title: routeSettings.orders.createOrder.title
        }
      },
      {
        path: routeSettings.orders.editOrder.path,
        loadComponent: () => import("./components/create-order/create-order.component").then(c => c.CreateOrderComponent),
        data: {
          title: routeSettings.orders.editOrder.title
        }
      },
      {
        path: routeSettings.orders.dispatchOrder.path,
        loadComponent: () => import("./components/dispatch-order/dispatch-order.component").then(c => c.DispatchOrderComponent),
        data: {
          title: routeSettings.orders.dispatchOrder.title
        }
      }
    ]
  },
];