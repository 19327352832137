import { importProvidersFrom } from "@angular/core";
import { Routes } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { routeSettings } from "../../core/routes.config";
import { EmployersState } from "../admin/employers/state/employers.state";
import { selectEmployerGuard } from "./guards/selectEmployer.guard";

export const userRoutes: Routes = [
  {
    path: routeSettings.users.path,
    data: { title: routeSettings.users.title },
    loadComponent: () => import('./components/users/users.component').then(c => c.UsersComponent),
    children: [
      {
        path: "login",
        loadComponent: () => import('./components/login/login.component').then(c => c.LoginComponent)
      },
      {
        path: "forgot",
        loadComponent: () => import('./components/forgot-pass/forgot-pass.component').then(c => c.ForgotPassComponent)
      },
      {
        path: "resetpassword",
        loadComponent: () => import('./components/reset-pass/reset-pass.component').then(c => c.ResetPassComponent)
      },
      {
        path: "select-employer",
        canDeactivate: [selectEmployerGuard],
        providers: [importProvidersFrom(NgxsModule.forFeature([EmployersState]))],
        loadComponent: () => import('./components/select-employer/select-employer.component').then(c => c.SelectEmployerComponent)
      }
    ]
  }
];