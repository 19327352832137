import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { CERTIFICATIONS_MISSING_ERROR, IS_ASSIGNED_MISSING_ERROR, SAME_START_TIME_MISSING_ERROR, ZERO_ASSIGNMENTS_MISSING_ERROR } from "src/app/shared/utils/utils";
import { NotificationService } from "../../core/services/notification.service";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private notifier: NotificationService) {}

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      //server error
      switch (error.status) {
        case 404:
          this.notifier.logError(error);
          break;
        case 400:
          this.notifier.showError(error?.error?.error?.validationErrors[0].message, 20000);
          break;
        case 403:
          if (
            error?.error?.error.code != CERTIFICATIONS_MISSING_ERROR &&
            error?.error?.error.code != ZERO_ASSIGNMENTS_MISSING_ERROR &&
            error?.error?.error.code != SAME_START_TIME_MISSING_ERROR &&
            error?.error?.error.code != IS_ASSIGNED_MISSING_ERROR
          )
            this.notifier.showError(error?.error?.error.message);
          break;
        case 500:
          this.notifier.showError("Server error. Please try again later. ");
          break;
        default:
          this.notifier.showError("Server error. Please try again later. ");
          break;
      }
    } else {
      //Client Error
      this.notifier.logError(error);
    }
  }
}
