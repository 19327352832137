<header class="px-6 py-4 bg-white shadow items-center relative flex justify-between">
  <div class="w-1/4">
    <img src="../../../../assets/logo.png" alt="Maritime Logo" width="100" class="ml-8" />
  </div>
  <div class="w-2/4 justify-center flex">
    <ul class="flex flex-wrap">
      @for (item of navItems; track $index) {
        <li class="rounded mr-5 hover:bg-gray-200">
          <a
            *permissionsRequired="item.permissions"
            [routerLink]="item.routerLink"
            routerLinkActive="bg-gray-200 rounded"
            class="uppercase text-sm text-gray-dark block py-2 px-4">
            {{ item.text }}
          </a>
        </li>
      }
    </ul>
  </div>
  <div class="w-1/4"></div>

  <app-user-select class="absolute w-1/4 flex justify-end" />
</header>
