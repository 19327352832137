
import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivateFn } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { ComponentCanDeactivate } from "src/app/core/interfaces/component-can-deactivate.interface";
import { UnsavedChangesDialogComponent } from "../components/unsaved-changes-dialog/unsaved-changes-dialog.component";

export const pendingChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (component) => {
  const dialog: MatDialog = inject(MatDialog);

  if (!component || component.canDeactivate()) {
    return true;
  }

  return Observable.create((observer: Observer<boolean>) => {
    let dialogRef = dialog.open(UnsavedChangesDialogComponent);

    dialogRef.afterClosed().subscribe(
      result => {
        observer.next(result);
        observer.complete();
      },
      error => {
        observer.next(false);
        observer.complete();
      }
    );
  });
}