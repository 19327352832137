import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { DispatchState } from "src/app/features/dispatch/state/dispatch.state";
import { OrderState } from "src/app/features/orders/state/order.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { CallsState } from "src/app/shared/states/calls/calls.state";
import { routeSettings } from "../../core/routes.config";
import { AvailabilityState } from "../availability/state/availability.state";
import { RosterState } from "../roster/states/roster/roster.state";
import { DispatchByDateState } from "./state/dispatch-by-date.state";

export const dispatchByDateRoutes: Route[] = [
  {
    path: routeSettings.dispatchByDate.path,
    providers: [importProvidersFrom(NgxsModule.forFeature([
      DispatchState,
      OrderState,
      UnionsState,
      AvailabilityState,
      RosterState,
      CallsState,
      DispatchByDateState
    ]))],
    canActivate: [authGuard],
    data: {
      title: routeSettings.dispatchByDate.title,
      permissions: [
        Permission.Administrator,
        Permission.UnionDispatcher,
        Permission.UnionSupervisor
      ]
    },
    loadComponent: () => import("./components/dispatch-by-date/dispatch-by-date.component").then(c => c.DispatchByDateComponent),
  },
];