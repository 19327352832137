import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { routeSettings } from "../../core/routes.config";
import { DispatchDashboardState } from "./state/dispatch-dashboard.state";

export const dispatchDashboardRoutes: Route[] = [
  {
		path: routeSettings.dispatchDashboard.path,
		data: { title: routeSettings.dispatchDashboard.title },
    providers: [importProvidersFrom(NgxsModule.forFeature([DispatchDashboardState]))],
		loadComponent: () => import("./components/dispatch-dashboard/dispatch-dashboard.component").then(c => c.DispatchDashboardComponent),
		children: [
      {
        path: routeSettings.dispatchDashboard.list.path,
        data: { title: routeSettings.dispatchDashboard.list.title },
        loadComponent: () => import("./components/dashboard-list/dashboard-list.component").then(c => c.DashboardListComponent)
      },
      {
        path: routeSettings.dispatchDashboard.availability.path,
        data: { title: routeSettings.dispatchDashboard.availability.title },
        loadComponent: () => import("./components/dispatch-availability/dispatch-availability.component").then(c => c.DispatchAvailabilityComponent)
      },
      {
        path: routeSettings.dispatchDashboard.workboard.path,
        data: { title: routeSettings.dispatchDashboard.workboard.title },
        loadComponent: () => import("./components/workboard/workboard.component").then(c => c.WorkboardComponent)
      },
      {
        path: '**',
        redirectTo: routeSettings.dispatchDashboard.list.path,
        pathMatch: 'full'
      }
    ]
	}
]