
import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, Observer } from "rxjs";
import { ComponentCanDeactivate } from "src/app/core/interfaces/component-can-deactivate.interface";
import { GoBackDialogComponent } from "../components/go-back-dialog/go-back-dialog.component";

export const selectEmployerGuard: CanDeactivateFn<ComponentCanDeactivate> = (component) => {
  const store: Store = inject(Store);
  const dialog: MatDialog = inject(MatDialog);

  const user = store.selectSnapshot<any>(stateStore => stateStore.users?.auth);
  if (component.canDeactivate() || !user.access_token) {
    return true;
  }

  return Observable.create((observer: Observer<boolean>) => {
    let dialogRef = dialog.open(GoBackDialogComponent);

    dialogRef.afterClosed().subscribe(
      result => {
        observer.next(false);
        observer.complete();
      },
      error => {
        observer.next(false);
        observer.complete();
      }
    );
  });
}