import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { RefreshToken } from 'src/app/features/users/state/users.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isTokenRefreshing: boolean = false;

  constructor(
    private store: Store
  ) {}
    
  intercept(request : HttpRequest<any>, next : HttpHandler): Observable<HttpEvent<any>> {
    // Check if the user is logging in for the first time
    return next.handle(this.attachTokenToRequest(request)).pipe(
      tap((event : HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
          // console.log("Success");
        // }
      }),
      catchError((err) : Observable<any> => {
        if (err instanceof HttpErrorResponse) {
          switch((<HttpErrorResponse>err).status) {
            case 401:
              console.log("Token expired. Attempting refresh ...");
              return this.handleHttpResponseError(request, next);
            default:
              return throwError(err);
          }
        } else {
          return throwError(this.handleError);
        }
      })
    );
  }

  // Global error handler method 
  handleError(errorResponse : HttpErrorResponse) {
    let errorMsg : string;

    if(errorResponse.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
      errorMsg = "An error occured : " + errorResponse.error.message;
    } 
    else {
        // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${errorResponse.status}, body was: ${errorResponse.error}`;
    }
    return throwError(errorMsg);
  }

  // Method to handle http error response
  handleHttpResponseError(request : HttpRequest<any>, next : HttpHandler) {
    // First thing to check if the token is in process of refreshing
    if(!this.isTokenRefreshing) {
      this.isTokenRefreshing = true;

      // Any existing value is set to null
      // Reset here so that the following requests wait until the token comes back from the refresh token API call
      this.tokenSubject.next(null);

      /// call the API to refresh the token
      return this.store.dispatch(new RefreshToken()).pipe(
        switchMap((tokenresponse: any) => {
          if (tokenresponse) {            
            return next.handle(this.attachTokenToRequest(request));
          }
        }),
        catchError(err => {
          return this.handleError(err);
        }),
        finalize(() => {
          this.isTokenRefreshing = false;
        })
      );
    }
    else {
      this.isTokenRefreshing = false;
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.attachTokenToRequest(request));
        }));
    }
  }

  attachTokenToRequest(request: HttpRequest<any>) {
    const token = this.store.selectSnapshot<string>((state) => state.users?.auth.access_token || null);
    const tenantId = this.store.selectSnapshot<string>((state) => state.prefillData?.tenantId || null);
    if (token)
      return tenantId 
        ? request.clone({setHeaders: {Authorization: `Bearer ${token}`, __tenant: tenantId} })
        : request.clone({setHeaders: {Authorization: `Bearer ${token}`} });
    if (tenantId)
      return request.clone({setHeaders: {__tenant: tenantId} });
    return request;
  }
}