import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [RouterModule, AsyncPipe, HeaderComponent],
  templateUrl: './main-page.component.html'
})
export class MainPageComponent {

  @Select(state => state.prefillData.loaded) prefillLoaded$: Observable<boolean>;
  @Select(state => state.prefillData.error) prefillDataError$: Observable<boolean>;
  @Select(state => state.users.loaded) user$: Observable<any>;
  
  constructor() {}

}
