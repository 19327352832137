import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { UsersPermissionsService } from 'src/app/features/admin/users-permissions/users-permissions.service';
import { LoadRecordings } from 'src/app/features/recordings/state/recordings.actions';
import { RosterService } from 'src/app/features/roster/services/roster.service';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
;

@Component({
  selector: 'app-recordings-filters',
  standalone: true,
  imports: [TextInputComponent, DropdownComponent],
  templateUrl: './recordings-filters.component.html'
})
export class RecordingsFiltersComponent implements OnInit {

  filtersForm: UntypedFormGroup;

  workerOptions: SelectOption[];
  searchingWorkers: boolean;

  callerOptions: SelectOption[];
  searchingCallers: boolean;
  
  private unsubscribe$: Subject<void>;

  constructor(
    private store: Store,
    private rosterService: RosterService,
    private userService: UsersPermissionsService
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.workerOptions = [];
    this.callerOptions = [];
  }

  ngOnInit(): void {
    this.filtersForm = new UntypedFormGroup({
      "SearchQ": new UntypedFormControl(''),
      "workerId": new UntypedFormControl(''),
      "callerId": new UntypedFormControl(''),
    });
    this.filtersForm.valueChanges.pipe(
      debounceTime(500),
			takeUntil(this.unsubscribe$)
    ).subscribe(
      (value) => {
        this.store.dispatch(
          new LoadRecordings(value)
        );
      }
    )
  }

  searchWorkerOptions(searchQ: string): void {
    if (searchQ?.length < 3) {
      this.workerOptions = [];
      return;
    }

    if (this.searchingWorkers) {
      return;
    }

    this.searchingWorkers = true;
    this.rosterService.getWorkers(0, 50, { searchQ }).pipe(
      take(1),
      tap(
        (response) => {
          this.workerOptions = response.items.map(
            (item) => {
              return {
                value: item.id,
                text: `${item.firstName} ${item.lastName}`
              };
            }
          );
          this.searchingWorkers = false;
        }
      )
    ).subscribe();
  }

  searchCallerOptions(searchQ: string): void {
    if (searchQ?.length < 3) {
      this.callerOptions = [];
      return;
    }

    if (this.searchingCallers) {
      return;
    }

    this.searchingCallers = true;
    this.userService.getUsers(0, 50, searchQ).pipe(
      take(1),
      tap(
        (response) => {
          this.callerOptions = response.items.map(
            (item) => {
              return {
                value: item.id,
                text: `${item.name} ${item.surname}`
              };
            }
          );
          this.searchingCallers = false;
        }
      )
    ).subscribe();
  }

}
