import { Routes } from "@angular/router";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";

export const reportsRoutes: Routes = [
  {
    path: routeSettings.reports.path,
    canActivate: [authGuard],
		data: {
			title: routeSettings.reports.title,
			permissions: [
				Permission.Administrator,
				Permission.ILAAdmin,
				Permission.USMXAdmin
			]
		},
    loadComponent: () => import('./components/reports/reports.component').then(c => c.ReportsComponent),
    children: [
      {
        path: "",
        loadComponent: () => import('./components/reports-list/reports-list.component').then(c => c.ReportsListComponent),
      },
      {
        path: routeSettings.reports.details.path,
        data: {
          title: routeSettings.reports.details.title
        },
        loadComponent: () => import('./components/report-detail/report-detail.component').then(c => c.ReportDetailComponent),
      }
    ]
  }
];