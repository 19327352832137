import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID, inject } from "@angular/core";
import { CanActivateFn, Route, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { UsersState } from "src/app/features/users/state/users.state";

export const authGuard: CanActivateFn = (route: Route) => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);
  const platformId: Object = inject(PLATFORM_ID);

  const claims = store.selectSnapshot(UsersState.auth)?.claims || null;  
  const permissions: Permission[] = route.data.permissions;
  
  let canNavigate: boolean = false;
  if (claims && permissions) {
    permissions.forEach(element => {
      if (claims.indexOf(element) !== -1) {
        canNavigate = true;
      }
    });
  } else {
    canNavigate = false;
  }

  if (canNavigate) {
    return true;
  } else {
    if (isPlatformBrowser(platformId)) {
      return router.parseUrl("users/login");
    } else {
      return router.parseUrl("loading");
    }
  }
}