import { importProvidersFrom } from "@angular/core";
import { Routes } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { pendingChangesGuard } from "../../shared/guards/pendingChanges.guard";
import { BerthsState } from "../admin/berths/state/berths.state";
import { DeptCodesState } from "../admin/dept-codes/state/dept-codes.state";
import { EmployersState } from "../admin/employers/state/employers.state";
import { TerminalsState } from "../admin/terminals/state/terminals.state";
import { UnionsState } from "../admin/unions/state/unions.state";
import { VesselsState } from "../admin/vessels/state/vessels.state";
import { OrderState } from "../orders/state/order.state";
import { RosterState } from "../roster/states/roster/roster.state";
import { exportTimesheetGuard } from "./guards/export-timesheet.guard";
import { LoadTimesheetResolver } from "./resolvers/load-timesheet.resolver";
import { ExportTimesheetState } from "./states/export-timesheet/export-timesheet.state";

const timesheetRoutes: Routes = [
  {
    path: routeSettings.timekeeping.jobInfo.path,
    data: { title: routeSettings.timekeeping.jobInfo.title },
    canDeactivate: [pendingChangesGuard],
    loadComponent: () => import("./components/timesheets-job-information/timesheets-job-information.component").then(c => c.TimesheetsJobInformationComponent)
  },
  {
    path: routeSettings.timekeeping.workersList.path,
    data: { title: routeSettings.timekeeping.workersList.title },
    loadComponent: () => import("./components/timesheets-workers-list/timesheets-workers-list.component").then(c => c.TimesheetsWorkersListComponent)

  },
  {
    path: routeSettings.timekeeping.activity.path,
    data: { title: routeSettings.timekeeping.activity.title },
    loadComponent: () => import("./components/timesheets-activity/timesheets-activity.component").then(c => c.TimesheetsActivityComponent)
  },
  {
    path: '**',
    redirectTo: routeSettings.timekeeping.jobInfo.path
  }
];

export const timesheetsRoutes: Routes = [
  {
    path: routeSettings.timekeeping.path,
		canActivate: [authGuard],
		data: {
			permissions: [
				Permission.Administrator,
				Permission.UnionTimekeeper,
				Permission.EmployerTimekeeper,
				Permission.EmployerSupervisor,
				Permission.SingleStepApprover
			],
			title: routeSettings.timekeeping.title
		},
    children: [
      {
        path: "",
        providers: [importProvidersFrom(NgxsModule.forFeature([
          UnionsState,
          VesselsState,
          EmployersState,
          TerminalsState,
          RosterState,
          OrderState,
          BerthsState,
          DeptCodesState,
          ExportTimesheetState
        ]))],
        loadComponent: () => import('./components/timesheets/timesheets.component').then(c => c.TimesheetsComponent),
        children: [
          {
            path: routeSettings.timekeeping.list.path,
            data: { title: routeSettings.timekeeping.list.title },
            loadComponent: () => import('./components/timesheets-list/timesheets-list.component').then(c => c.TimesheetsListComponent),
          },
          {
            path: routeSettings.timekeeping.byOrder.path,
            data: { title: routeSettings.timekeeping.byOrder.title },
            loadComponent: () => import('./components/timesheets-by-labor-order/timesheets-by-labor-order.component').then(c => c.TimesheetsByLaborOrderComponent),
          },
          {
            path: routeSettings.timekeeping.exportTimesheet.path,
            data: { title: routeSettings.timekeeping.exportTimesheet.title },
            canActivate: [exportTimesheetGuard],
            loadComponent: () => import("./components/export-timesheet/export-timesheet.component").then(c => c.ExportTimesheetComponent)
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: routeSettings.timekeeping.byOrder.path
          }
        ]
      },  
      {
        path: routeSettings.timekeeping.editSheet.path,
        resolve: {
          timesheet: LoadTimesheetResolver
        },
        runGuardsAndResolvers: 'always',
        loadComponent: () => import('./components/timesheets-edit/timesheets-edit.component').then(c => c.TimesheetsEditComponent),
        children: [
          {
            path: '',
            loadComponent: () => import("./components/timesheets-content/timesheets-content.component").then(c => c.TimesheetsContentComponent),
            children: timesheetRoutes
          }
        ]
      },
      {
        path: routeSettings.timekeeping.laborOrderTimesheets.path,
        data: { title: routeSettings.timekeeping.laborOrderTimesheets.title },
        loadComponent: () => import("./components/timesheets-labor-order/timesheets-labor-order.component").then(c => c.TimesheetsLaborOrderComponent),
        children: [
          {
            path: ':timesheetId',
            resolve: {
              timesheet: LoadTimesheetResolver
            },
            runGuardsAndResolvers: 'always',
            loadComponent: () => import("./components/timesheets-content/timesheets-content.component").then(c => c.TimesheetsContentComponent),
            children: timesheetRoutes
          }
        ]
      },
      {
        path: '**',
        redirectTo: routeSettings.timekeeping.byOrder.path
      }
    ]
  }
];