import { Routes } from "@angular/router";
import { routeSettings } from "./core/routes.config";
import { adminRoutes } from "./features/admin/admin.routes";
import { availabilityRoutes } from "./features/availability/availability.routes";
import { dispatchByDateRoutes } from "./features/dispatch-by-date/dispatch-by-date.routes";
import { dispatchDashboardRoutes } from "./features/dispatch-dashboard/dispatch-dashboard.routes";
import { dispatchRoutes } from "./features/dispatch/dispatch.routes";
import { ordersRoutes } from "./features/orders/orders.routes";
import { podiumRoutes } from "./features/podium/podium.routes";
import { recordingRoutes } from "./features/recordings/recordings.routes";
import { redirectRoutes } from "./features/redirect/redirect.routes";
import { reeferMechanicRoutes } from "./features/reefer-mechanic/reefer-mechanic.routes";
import { reportsRoutes } from "./features/reports/reports.routes";
import { rosterRoutes } from "./features/roster/roster.routes";
import { timesheetsRoutes } from "./features/timesheets/timesheets.routes";
import { uploadRoutes } from "./features/upload/upload.routes";
import { userProfileRoutes } from "./features/user-profile/user-profile.routes";
import { userRoutes } from "./features/users/users.routes";

export const routes: Routes = [
	{
		path: "",
		redirectTo: routeSettings.roster.path,
		pathMatch: "full",
		data: { title: routeSettings.roster.title }
	},
	{
		path: "loading",
		loadComponent: () => import("./shared/components/app-loading/app-loading.component").then(m => m.AppLoadingComponent)
	},
	...ordersRoutes,
	...dispatchRoutes,
	...dispatchByDateRoutes,
	...availabilityRoutes,
	...podiumRoutes,
	...redirectRoutes,
	...dispatchDashboardRoutes,
	...adminRoutes,
	...timesheetsRoutes,
	...userRoutes,
	...reeferMechanicRoutes,
	...reportsRoutes,
	...reportsRoutes,
	...uploadRoutes,
	...recordingRoutes,
	...userProfileRoutes,
	...rosterRoutes,
];
