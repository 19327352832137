import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogFooterComponent } from "../dialog-footer/dialog-footer.component";

@Component({
  selector: "app-unsaved-changes-dialog",
  standalone: true,
  imports: [DialogFooterComponent],
  templateUrl: "./unsaved-changes-dialog.component.html"
})
export class UnsavedChangesDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
  ) {}

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
