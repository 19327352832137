<div class="flex items-center cursor-pointer" [matMenuTriggerFor]="menu">
  <div class="mr-4">
    <app-avatar [imageUrl]="'../../../assets/avatar.svg'"></app-avatar>
  </div>
  <div class="flex flex-col pb-6">
    <div class="font-bold text-gray-700">{{ fullName$ | async }}</div>    
    <div class="font-bold text-gray-700"></div>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <div class="w-56">
        <a *permissionsRequired="[Permission.UnionTimekeeper]"
           routerLink="/users/select-employer"
           class="focus:outline-none block px-6 py-2 text-gray-900 hover:bg-gray-200">
          Select Employer
        </a>
        <a [routerLink]="['/profile']" class="focus:outline-none block px-6 py-2 text-gray-900 hover:bg-gray-200">
          My Profile
        </a>
        <a (click)="logout()" class="focus:outline-none block px-6 py-2 text-gray-900 hover:bg-gray-200">
          Log Out
        </a>
      </div>
    </mat-menu>
  </div>
  <div class="ml-4">
    <mat-icon class="text-gray-600">
      expand_more
    </mat-icon>
  </div>
</div>
