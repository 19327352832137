import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { routeSettings } from "src/app/core/routes.config";
import { PermissionDirective } from "src/app/shared/directives/permission-standalone.directive";
import { NavigationItem } from "../../../shared/interfaces/navigation-item.interface";
import { Permission } from "../../enums/permission.enum";
import { UserSelectComponent } from "../user-select/user-select.component";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [RouterModule, PermissionDirective, UserSelectComponent],
  templateUrl: "./header.component.html"
})
export class HeaderComponent {
  navItems: Array<NavigationItem>;

  constructor() {
    this.navItems = [
      {
        text: "Timekeeping",
        routerLink: ["/timekeeping"],
        permissions: [
          Permission.Administrator,
          Permission.UnionTimekeeper,
          Permission.EmployerTimekeeper,
          Permission.EmployerSupervisor,
          Permission.SingleStepApprover
        ]
      },
      {
        text: "Roster",
        routerLink: ["/labor"],
        permissions: [
          Permission.Administrator,
          Permission.UnionAdministrator,
          Permission.UnionAdminReadonly,
          Permission.UnionDispatcher,
          Permission.StevedoringAssociation
        ]
      },
      {
        text: "Orders",
        routerLink: ["/orders"],
        permissions: [
          Permission.Administrator,
          Permission.UnionDispatcher,
          Permission.EmployerTest,
          Permission.UnionSupervisor
        ]
      },
      {
        text: "Admin Panel",
        routerLink: ["/admin"],
        permissions: [
          Permission.Administrator,
          Permission.EmployerTest,
          Permission.EmployerSupervisor,
          Permission.SingleStepApprover
        ]
      },
      {
        text: "Reefer Mechanic",
        routerLink: ["/reefermechanic"],
        permissions: [
          Permission.Administrator,
          Permission.ReeferMechanic
        ]
      },
      {
        text: "Reports",
        routerLink: ["/reports"],
        permissions: [
          Permission.Administrator,
          Permission.ILAAdmin,
          Permission.USMXAdmin
        ]
      },
      {
        text: "Upload",
        routerLink: ["/upload"],
        permissions: [
          Permission.Administrator,
          Permission.EmployerUploads
        ]
      },
      {
        text: "Recordings",
        routerLink: [routeSettings.recordings.path],
        permissions: [
          Permission.Administrator,
          Permission.CallRecords
        ]
      }
    ];
  }
}
