import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { DashboardState } from "src/app/shared/states/dashboard.state";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { UnionsState } from "../admin/unions/state/unions.state";
import { workerRoutes } from "../worker/worker-routing.module";
import { GroupMessageState } from "./states/group-message/group-message.state";
import { RosterSenioritiesState } from "./states/roster-seniorities/roster-seniorities.state";
import { RosterState } from "./states/roster/roster.state";

export const rosterRoutes: Route[] = [
	{
		path: 'labor',
		canActivate: [authGuard],
		data: {
			permissions: [
				Permission.Administrator,
				Permission.UnionAdministrator,
				Permission.UnionAdminReadonly,
				Permission.UnionDispatcher,
				Permission.StevedoringAssociation
			]
		},
		providers: [
			importProvidersFrom(
				NgxsModule.forFeature([
					DashboardState,
					RosterState,
					UnionsState
				])
			)
		],
		loadComponent: () => import("./components/roster-main/roster-main.component").then(c => c.RosterMainComponent),
		children: [
			{
				path: routeSettings.roster.groupMessage.path,
				canActivate: [authGuard],
				data: {
					permissions: [
						Permission.Administrator,
						Permission.UnionAdministrator,
						Permission.UnionAdminReadonly,
						Permission.UnionDispatcher
					],
					title: routeSettings.roster.groupMessage.title
				},
				providers: [
					importProvidersFrom(
						NgxsModule.forFeature([
							GroupMessageState
						])
					)
				],
				loadComponent: () => import("./components/group-message/group-message.component").then(c => c.GroupMessageComponent),
				children: [
					{
						path: '',
						loadComponent: () => import("./components/group-message-list/group-message-list.component").then(c => c.GroupMessageListComponent)
					},
					{
						path: routeSettings.roster.createGroupMessage.path,
						loadComponent: () => import("./components/group-message-create/group-message-create.component").then(c => c.GroupMessageCreateComponent)
					},
					{
						path: routeSettings.roster.updateGroupMessage.path,
						loadComponent: () => import("./components/group-message-create/group-message-create.component").then(c => c.GroupMessageCreateComponent)
					}
				]
			},
			...workerRoutes,
			{
				path: '',
				loadComponent: () => import("./components/roster/roster.component").then(c => c.RosterComponent),
				children: [
					{
						path: 'all-workers',
						canActivate: [authGuard],
						data: {
							permissions: [
								Permission.Administrator,
								Permission.UnionAdministrator,
								Permission.UnionAdminReadonly,
								Permission.UnionDispatcher,
								Permission.StevedoringAssociation
							],
							title: routeSettings.roster.title
						},
						loadComponent: () => import("./components/roster-all-workers/roster-all-workers.component").then(c => c.RosterAllWorkersComponent),
					},
					{
						path: 'seniority',
						canActivate: [authGuard],
						data: {
							permissions: [
								Permission.Administrator,
								Permission.UnionAdministrator,
								Permission.UnionDispatcher
							],
							title: routeSettings.roster.title
						},
						providers: [
							importProvidersFrom(
								NgxsModule.forFeature([
									RosterSenioritiesState
								])
							)
						],
						loadComponent: () => import("./components/roster-seniorities/roster-seniorities.component").then(c => c.RosterSenioritiesComponent),
					},
					{
						path: '**',
						redirectTo: 'all-workers',
						pathMatch: 'full'
					}
				]
			}
		]
	}
];