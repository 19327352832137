import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { RecordingStatus } from "../enums/recording-status.enum";
import { RecordingFilters } from "../interfaces/recording-filters";
import { RecordingsService } from "../services/recordings.service";
import { LoadRecordings, RemoveRecording } from "./recordings.actions";
import { RecordingsStateModel } from "./recordings.model";

@State<RecordingsStateModel>({
  name: "recorings",
  defaults: {
    recordings: [],
    recordingFilters: {
      SkipCount: 0,
      MaxResultCount: 30
    },
    totalCount: 0,
    loading: false,
    working: false,
    error: false
  }
})
@Injectable()
export class RecordingsState {
  
  constructor(
    private recordingsService: RecordingsService
  ) {}

  @Selector() static recordings(state: RecordingsStateModel) { return state.recordings; }
  @Selector() static totalRecordings(state: RecordingsStateModel) { return state.totalCount; }
  @Selector() static loading(state: RecordingsStateModel) { return state.loading; }
  @Selector() static working(state: RecordingsStateModel) { return state.working; }
  @Selector() static error(state: RecordingsStateModel) { return state.error; }

  @Action(LoadRecordings)
  loadRecordings(ctx: StateContext<RecordingsStateModel>, action: LoadRecordings) {
    const state = ctx.getState();
    const recordingFilters: RecordingFilters = {
      ...state.recordingFilters,
      ...action.filters
    };

    ctx.patchState({
      recordings: [],
      recordingFilters,
      totalCount: 0,
      loading: true,
      error: false,
    });
    
    return this.recordingsService.getRecordings(recordingFilters).pipe(
      tap(
        (response) => {
          ctx.patchState({
            recordings: response.items.map(
              (r) => {
                let recording = { ...r };
								recording.creationTime = new Date(r.creationTime + '+0000');
                if (recording.recordStatus !== RecordingStatus.Completed) {
                  recording.callUrl = null;
                }
                return recording;
              }
            ),
            totalCount: response.totalCount,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({
            recordings: [],
            totalCount: 0,
            error: true,
            loading: false
          });
        }
      )
    );
  }

  @Action(RemoveRecording)
  deleteRecording(ctx: StateContext<RecordingsStateModel>, action: RemoveRecording) {
    return this.recordingsService.removeRecording(action.recordingId).pipe(
      tap(
        () => {
          const state = ctx.getState();
          ctx.patchState({
            recordings: state.recordings.filter(
              (item) => {
                return item.id !== action.recordingId;
              }
            )
          });
        }
      )
    );
  }
}
