import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { EmployersState } from "../admin/employers/state/employers.state";
import { ReeferMechanicState } from "./state/reefer-mechanic.state";

export const reeferMechanicRoutes: Route[] = [
	{
		path: routeSettings.reefermechanic.path,
		canActivate: [authGuard],
		data: {
			title: routeSettings.reefermechanic.title,
			permissions: [
				Permission.Administrator,
				Permission.ReeferMechanic
			]
		},
		providers: [importProvidersFrom(NgxsModule.forFeature([EmployersState, ReeferMechanicState]))],
		loadComponent: () => import("./components/reefer-mechanic/reefer-mechanic.component").then(c => c.ReeferMechanicComponent)
	}
];
