import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogFooterComponent } from "src/app/shared/components/dialog-footer/dialog-footer.component";

@Component({
  selector: "app-go-back-dialog",
  standalone: true,
  imports: [DialogFooterComponent],
  templateUrl: "./go-back-dialog.component.html"
})
export class GoBackDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<GoBackDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
