import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { routeSettings } from "src/app/core/routes.config";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { OrderState } from "src/app/features/orders/state/order.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { AvailabilityState } from "../availability/state/availability.state";
import { DispatchState } from "./state/dispatch.state";

export const dispatchRoutes: Route[] = [
  {
    path: routeSettings.dispatch.path,
    providers: [importProvidersFrom(NgxsModule.forFeature([
      DispatchState,
      OrderState,
      UnionsState,
      AvailabilityState
    ]))],
    canActivate: [authGuard],
    data: {
      permissions: [
        Permission.Administrator, 
        Permission.UnionDispatcher, 
        Permission.UnionSupervisor
      ]
    },
    loadComponent: () => import("./components/dispatch/dispatch.component").then(c => c.DispatchComponent),
    children: [
      {
        path: "",
        data: {
          title: routeSettings.dispatch.title
        },
        loadComponent: () => import("./components/dispatch-order-list/dispatch-order-list.component").then(c => c.DispatchOrderListComponent),
      }, 
      {
        path: routeSettings.dispatch.dispatchAssign.path,
        data: {
          title: routeSettings.dispatch.dispatchAssign.title
        },
        loadComponent: () => import("./components/dispatch-assign/dispatch-assign.component").then(c => c.DispatchAssignComponent),
      }
    ]
  },
];
