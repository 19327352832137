import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "src/app/core/enums/permission.enum";
import { routeSettings } from "src/app/core/routes.config";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { authGuard } from "src/app/shared/guards/auth.guard";
import { DashboardState } from "src/app/shared/states/dashboard.state";
import { RosterState } from "../roster/states/roster/roster.state";
import { AvailabilityState } from "./state/availability.state";

export const availabilityRoutes: Route[] = [
  {
    path: routeSettings.availability.path,
    canActivate: [authGuard],
    providers: [importProvidersFrom(NgxsModule.forFeature([
      AvailabilityState,
      DashboardState,
      UnionsState,
      RosterState
    ]))],
    data: {
      title: routeSettings.availability.title,
      permissions: [
        Permission.Administrator,
        Permission.UnionDispatcher,
        Permission.UnionSupervisor
      ]
    },
    loadComponent: () => import("./components/availability/availability.component").then(c => c.AvailabilityComponent)
  },
];