import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ExportTimesheetState } from '../states/export-timesheet/export-timesheet.state';

export const exportTimesheetGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);
  
  if (store.selectSnapshot(ExportTimesheetState.exportType)) {
    return true;
  } else {
    router.navigate(['/timekeeping', 'list']);
    return false;
  }
} 