import { importProvidersFrom } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { Permission } from "../../core/enums/permission.enum";
import { routeSettings } from "../../core/routes.config";
import { authGuard } from "../../shared/guards/auth.guard";
import { EmployersState } from "../admin/employers/state/employers.state";

export const uploadRoutes: Route[] = [
	{
		path: routeSettings.upload.path,
		canActivate: [authGuard],
		data: {
			title: routeSettings.upload.title,
			permissions: [
				Permission.Administrator,
				Permission.EmployerUploads
			]
		},
		providers: [importProvidersFrom(NgxsModule.forFeature([EmployersState]))],
		loadComponent: () => import('./components/upload/upload.component').then(c => c.UploadComponent)

	}
]